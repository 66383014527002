section.freeconsultation_page .head-sub-head.text-center {
	margin: 3rem 0;
}
.consultation_form {
	background: #e3f4fb;
	padding: 2rem;
	border: 1px solid #249fd861;
	padding-bottom: 1rem;
	margin-bottom: 2rem;
}
/* custom check box css start here */
.custom_check {
	display: block;
	margin-bottom: 15px;
}

.custom_check input {
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;
}

.custom_check label {
	font-size: 12px;
	color: #000000;
	position: relative;
	cursor: pointer;
	font-family: "Poppins";
	font-weight: 500;
}

.custom_check label:before {
	content: "";
	-webkit-appearance: none;
	background-color: transparent;
	border: 2px solid #d4d4d4;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
		inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
	padding: 7px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 5px;
}

.custom_check input:checked + label:after {
	content: "";
	display: block;
	position: absolute;
	top: 1px;
	left: 6px;
	width: 6px;
	height: 14px;
	border: solid #249fdb;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
/* custom check box css end here */
.sign-up-btn button {
	font-size: 12px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 400;
	background-color: #249fdb;
	padding: 18px 21px;
	line-height: 1;
	border: 1px solid #249fdb;
	width: 100%;
}
.consultation_form h4 {
	font-size: 20px;
	color: #249fdb;
	font-family: "Poppins";
	font-weight: 600;
	margin: 0;
	margin-bottom: 15px;
}
.consultation_form p {
	font-size: 13px;
	color: #00151fb0;
	font-family: "Poppins";
	font-weight: 400;
}
section.black_freeconsult {
	background: #000;
	color: #fff;
	padding: 2rem;
	padding-bottom: 1rem;
	margin-bottom: 60px;
}
section.black_freeconsult p {
	color: #cccccc;
	font-size: 14px;
	line-height: 1.5;
}

.consultation_form input {
	border-radius: unset;
}

.consultation_form .form-group.custom_check {
	margin: 0;
}

select#exampleFormControlSelect1 {
	color: #0000006b;
	font-weight: 400;
}

.free-form {
	background-color: #e3f4fb;
	padding: 30px;
	border: 1px solid #249fd8;
}

.free-consultation-btn button {
	background-color: #249fdb;
	width: 50%;
	padding: 16px;
	color: #ffffffff;
	border: unset;
	margin: 0 auto;
}

.free-form input {
	border-radius: unset;
	/* padding: 0 !important; */
	padding: 0px 17px !important;
}
.free-form textarea#exampleFormControlTextarea1 {
	height: 180px;
	resize: none;
}

.free-consultation-btn {
	text-align: center;
	margin-top: 20px;
}

.free-form textarea#exampleFormControlTextarea1::placeholder {
	color: #0000006b;
}

.free-form-head h3 {
	font-size: 30px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
}

.free-form-head p {
	font-size: 16px;
	color: #00151fa6;
	font-family: "Poppins";
	font-weight: 400;
}

.form-group.custom_check a {
	color: #249fdb;
}

.free-consltation-sec {
	padding-top: 50px;
}

.free-consltation-sec p {
	font-size: 14px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 400;
}

.form-group.custom_check button {
	background-color: transparent;
	border: unset;
	outline: none;
}

@media (max-width: 768px) {
	.consultation_form {
		padding: 1rem;
	}
	.sign-up-btn button {
		font-size: 12px;
		padding: 16px 21px;
	}
}

@media (max-width: 575px) {
	section.freeconsultation_page .head-sub-head.text-center h2 {
		font-size: 16px;
	}
	.free-consltation-sec {
		padding-top: 20px;  
	}
  .free-consltation-sec p span {
    font-size: 12px ! important;
}
}

@media (max-width: 320px) {
	section.freeconsultation_page .head-sub-head.text-center h2 {
		font-size: 15px;
	}
}
