.cash-program-sec .video-div video {
	overflow: hidden;
	height: 500px;
	width: 100%;
}
.cash-program-sec .arrow-text {
	font-size: 12px;
	margin: 0;
	text-align: center;
	font-weight: bold;
}
.cash-program-sec .last-img-text {
	font-size: 14px;
	font-weight: bold;
}
.cash-program-sec .bar-img-cont {
	width: 80%;
}

.cashprogram_img_onlyy {
	height: 300px;
	width: 100%;
	object-fit: cover;
}

.cash-program-sec .last-text {
	text-align: center;
	font-weight: 600;
	font-size: 14px;
}
.all-forms-sec h4 {
	font-size: 25px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
}

.all-forms-sec h3 {
	font-size: 25px;
	color: #249fdb;
	font-family: "Poppins";
	font-weight: 600;
}

.all-forms-sec p {
	font-size: 12px;
	color: #00151fa8;
	font-family: "Poppins";
	font-weight: 400;
}

.all-forms-sec {
	background-color: #e3f4fb;
	border: 1px solid #249fd8;
	padding: 40px 30px;
}
.all-forms-sec form label {
	color: #00151f;
	font-size: 12px;
	font-family: "Poppins";
	font-weight: 400;
	padding-left: 13px;
	margin: 20px 0px;
}

.form-submit-btn button {
	background-color: #249fdb;
	width: 100%;
	border: none;
	padding: 10px;
	color: #fff;
	text-decoration: unset;
}

.all-forms-sec .form-group input {
	height: 55px;
	border-radius: unset;
}

.cash-pro-img {
	position: relative;
}

.play-btn {
	position: absolute;
	top: 100px;
	left: 0;
	right: 0;
	bottom: unset;
	display: flex;
	align-items: center;
	justify-content: center;
}

.play-btn i {
	color: #249fdb;
	background-image: url("../img/playback.png");
	background-repeat: no-repeat;
	width: auto;
	background-size: 100% 100%;
	padding: 26px 29px;
}

/* .dollar-img img {
    background-color: #141414;
    padding: 10px;
    border-radius: 40px;
} */
.process-box-2 {
}
.process-box {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.process-para p {
	font-size: 10px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
	padding-left: 12px;
}

.process-head h3 {
	font-size: 32px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
}

.process-head {
	padding: 20px 0px;
}

section.cash-program-sec {
	padding: 70px 0px;
}

.franchise-velocity {
	padding-top: 80px;
	padding-bottom: 40px;
}

.all-forms-sec form label span {
	color: #249fdb;
}

select#state {
	border-radius: unset;
	color: #0000007d;
	padding: 4px;
}

section.category-sec {
	padding: 100px 0px;
}

.dollar-img .img-fluid {
	max-width: 100px;
	height: auto;
}

@media (max-width: 576px) {
	section.category-sec {
		padding: 40px 0px;
	}
	section.cash-program-sec p {
		font-size: 12px;
	}
}
