/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.video-head {
  margin-top: 48px;
  font-size: 38px;
  font-weight: 600;
}
.sigCanvas {
  border: 1px solid;
  height: 200px;
  width: 100%;
  margin-top: 20px;
}
.disclosure input {
  width: 100%;
  padding: 8px;
}
.disclosure .clear {
  padding: 9px;
  background: #a22d2d;
  color: #fff;
}
.disclosure .Capture {
  padding: 9px;
  background: #1d81b2;
  color: #fff;
}
.disclosure .sig-img {
  overflow: hidden;
  height: 100px;
  width: 100%;
}
.disclosure .sig-img img {
  height: 100px;
  width: 100%;
}
.confidential-btn {
  font-size: 14px;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 400;
  background-color: #249fdb;
  padding: 18px 21px;
  line-height: 1;
  border: 1px solid #249fdb;
  width: 100%;
}

.download-btn {
  background-color: #387ea0;
  padding: 10px 20px;
  color: aliceblue;
}
.state-hide {
  display: none;
}
.state-show {
  display: block;
}

.table-logo {
  width: 120px;
  height: 120px;
  overflow: hidden;
}
.table-logo img {
  width: 100%;
  height: 100px;
}
.common-btn {
  font-size: 12px;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 400;
  background-color: #249fdb;
  padding: 14px 40px;
}
.icon-delete {
  font-size: 20px;
}
.icon-delete:hover {
  color: brown;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.payment-default-btn {
  background-color: #249fdb;
  width: auto;
  margin: 0 auto;
  padding: 10px;
  font-size: 18px;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 400;
  border: unset;
  font-size: 15px;
}

.consultation-modal .modal-close {
  position: absolute;
  right: 0;
  top: 8px;
  right: 9px;
}

.payment-modal {
}
.payment-modal .modal-content {
  padding: 45px 22px;
}

.payment-modal .modal-close {
  position: absolute;
  right: 0;
  top: 12px;
  right: 10px;
}

.notFound-container {
  text-align: center;
  /* padding: 90px 20px; */
  width: 80%;
}
.notFound-container .card-info-img {
  height: 315px;
}

.multi-image-upload {
  position: absolute;
  width: 100%;
}

.file-opc {
  opacity: 0;
}
.multi-imglist {
  list-style: none;
  display: flex;

  position: inherit;
  top: 55px;
}

.multi-imglist li {
  margin: 10px;
}

.hide {
  display: none;
}
.show {
  display: block;
}

.header-profile {
  height: 30px;
  width: 30px;
  overflow: hidden;
  /* background-color: aliceblue; */
  border-radius: 50%;
}

.check-Icon {
  color: darkgreen;
}
.check-Icon2 {
  color: rgb(185, 185, 185);
}

.profile-img-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stripe-Paybtn {
  font-size: 16px;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 400;
  background-color: #249fdb;
  width: 100%;
  border: none;
  padding: 10px;
}
