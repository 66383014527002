.our-process h5 {
	font-size: 14px;
	color: #249fdb;
	font-family: "Poppins";
	font-weight: 500;
}

.our-process p {
	font-size: 12px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 400;
}
.our-process {
	box-shadow: -8px -9px 12px #7070706e;
	padding: 40px 40px;
	overflow: hidden;
	z-index: 4;
	position: relative;
	height: 345px;
}
.our-process::before {
	content: "\f054";
	font-family: "FontAwesome";
	font-size: 70px;
	position: absolute;
	color: #249fdb;
	left: -11px;
	z-index: -3;
}

section.our-process-sec .col-md-2 {
	padding: 0;
}

.our-process-head h5 {
	font-size: 21px;
	color: #249fdb;
	font-family: "Poppins";
	margin: 0;
	font-weight: 600;
}

.our-process-head h2 {
	font-size: 40px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
}

.our-process-head {
	text-align: center;
	padding-bottom: 40px;
}

section.our-process-sec {
	padding: 90px 0px;
}

@media (max-width: 991px) {
	.our-process p {
		font-size: 11px;
	}
	.our-process h5 {
		font-size: 12px;
	}
	.our-process {
		padding: 31px 5px 0 29px;
	}
	.our-process::before {
		left: -19px;
	}
}
@media (max-width: 576px) {
	.our-process {
		padding: 21px 40px;
		height: 118px;
	}
	.our-process::before {
		left: -12px;
		top: 6px;
	}
	section.our-process-sec {
		padding: 36px 0px 75px 0;
	}
	.our-process-head h2 {
		font-size: 27px;
	}
	.our-process-head h5 {
		font-size: 18px;
		margin-bottom: 10px;
	}
}
