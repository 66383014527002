.atax-dv h4 span {
	font-size: 16px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 400;
	float: right;
}

.atax-dv p {
	font-size: 13px;
	color: #858585;
	font-family: "Poppins";
	font-weight: 400;
	margin-top: 14px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.atax-dv {
	border: 1px solid #dbdbdb;
	padding: 5px 20px;
	position: relative;
}

.atax-dv h4 {
	font-size: 16px;
	color: #242a2d;
	font-family: "Poppins";
	font-weight: 600;
}

.atax-dv h3 {
	font-size: 18px;
	color: #242a2d;
	font-family: "Poppins";
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.atax-dv h3 span {
	padding-left: 20px;
}

.req-head h3 {
	font-size: 25px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
}

.req-head p {
	font-size: 16px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 400;
}

.form-buctket-done-btn button {
	background-color: #249fdb;
	padding: 10px 20px;
	font-size: 18px;
	color: #ffffff;
	font-family: "Poppins";
	border: none;
	width: 40%;
	margin: 0 auto;
}

.all-for-scroll .image-cont {
	height: 180px;
	width: 180px;
	overflow: hidden;
	margin: 0px auto;
}

.all-for-scroll .image-cont img {
	height: 180px;
	width: 180px;
}

.form-buctket-done-btn {
	text-align: center;
}

section.request-sec {
	padding: 90px;
}

.all-cover-things {
	background-color: #e3f4fb;
	padding: 40px 30px;
}

.all-cover-things textarea#exampleFormControlTextarea1::placeholder {
	color: #0000005e;
	/* resize: none; */
}

.all-cover-things textarea#exampleFormControlTextarea1 {
	height: 115px;
	resize: none;
	border-radius: no;
	border-radius: unset;
}

.atax-dv {
	border: 1px solid #dbdbdb;
	padding: 20px 20px;
	position: relative;
	margin-top: 12px;
	background-color: #ffffffff;
}

.all-cover-things input#exampleInputEmail1 {
	border-radius: uun;
	border-radius: unset;
}

.dv-close {
	position: absolute;
	top: 8px;
	right: 10px;
}
@media (max-width: 576px) {
	section.request-sec {
		padding: 60px 20px;
	}
	.form-buctket-done-btn button {
		font-size: 14px;
		width: 70%;
	}
}
