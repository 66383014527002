/* Quiz Css Start Here */
.quiz {
	padding: 28px 0px;
}
.quiz .quiz_box {
	background: #e3f4fb;
	padding: 30px 50px 200px;
	position: relative;
}
.quiz .quiz_box .heading {
	text-align: center;
	padding-bottom: 10px;
}
.quiz .quiz_box .heading h5 {
	font-size: 21px;
	color: #249fdb;
	font-family: "Poppins";
	font-weight: 500;
}
.quiz .quiz_box .heading h2 {
	font-size: 40px;
	color: #00151f;
	font-weight: 600;
	font-family: "Poppins";
}
.quiz .quiz_box .progress {
	background: #0f0f0f;
	border-radius: 0;
	height: 3px;
	width: 75%;
	margin: 0px auto;
}
.quiz .quiz_box .progress .progress-bar {
	height: 3px;
	background: #249fdb;
	position: absolute;
}
.quiz .quiz_box .quiz_ques {
	padding: 50px 0;
}
.quiz .quiz_box .quiz_ques h2 {
	font-size: 28px;
	color: #00151f;
	font-weight: 800;
	font-family: "Poppins";
	text-align: center;
}
.quiz .quiz_box .answer {
	max-width: 50%;
	margin: 0px auto;
}
.quiz .quiz_box .answer button.franchise-btn {
	width: 100%;
	margin-bottom: 20px;
	background: #249fdb;
	border: 0;
	padding: 16px 0;
	font-family: "Poppins";
	color: #fff;
	font-weight: 500;
	box-shadow: 0px 4px 11px #ccc;
	cursor: pointer;
	transition: 0.6s;
}
.quiz .quiz_box .answer button.franchise-btn:hover {
	background: #000;
}
.quiz .quiz_box .answer button.franchise-btn-select {
	background: #000;
}
.quiz .quiz_box .progress .progress_count h5 {
	font-size: 16px;
	color: #00151f;
	font-weight: 400;
	font-family: "Poppins";
}
.quiz .quiz_box .progress .progress_count {
	position: absolute;
	right: 5%;
	top: 17%;
}
.quiz .quiz_box .answer p {
	font-size: 14px;
	color: #030303cf;
	font-family: "Poppins";
	line-height: 26px;
}
.quiz .quiz_box form {
	padding-bottom: 20px;
	max-width: 50%;
	margin: 0px auto;
}
.quiz .quiz_box form .form-group .form-control {
	border-radius: 0;
	height: 65px;
	/* padding-top: 0; */
	/* padding-left: 70px; */
	padding-left: 20px;
	/* background-image: url("../img/emaill.png"); */
	background-repeat: no-repeat;
	background-position: center left 35px;
	font-size: 16px;
	color: #00000096;
}

.quiz .quiz_box form .form-group .form-control::placeholder {
	font-size: 16px !important;
}

.quiz .quiz_box input::placeholder {
	font-size: 12px;
	font-weight: bold;
}

.quiz .quiz_box form .form-group .form-control.frst_name {
	background-image: url("../img/name.png");
}
.quiz .quiz_box form .form-group .form-control.phone {
	background-image: url("../img/phonee.png");
}
.quiz .quiz_box form .form-group .form-control.code {
	background-image: url("../img/code.png");
}
.quiz .quiz_box form .form-group .form-control::placeholder {
	font-size: 12px;
	color: #00000096;
}
.quiz .quiz_box form button.newsletter {
	background: #249fdb;
	border-radius: 0;
	border: 0;
	outline: none;
	box-shadow: none;
	color: #fff;
	font-family: "Poppins";
	height: 60px;
	width: 100%;
	font-size: 14px;
	cursor: pointer;
}
.quiz .quiz_box form .button-group.two-btn button {
	width: 47.5%;
}
.quiz .quiz_box form .button-group.two-btn button.newsletter.grey {
	background: #a4a4a4;
}
.quiz .quiz_box form .button-group.two-btn span.spacer {
	margin: 0 10px;
}
.quiz .quiz_box form .button-group.two-btn span.spacer {
	margin: 0 10px;
}
.quiz .quiz_box form .button-group.two-btn button.newsletter.grey {
	background: #a4a4a4;
}
.quiz .quiz_box .quiz_ques p {
	text-align: center;
	color: #00151f;
	font-family: "Poppins";
	font-size: 15px;
	max-width: 45%;
	margin: 0px auto;
}
.quiz .quiz_box .quiz_ques figure {
	text-align: center;
	margin: 25px 0 0 0;
}
@media (max-width: 575px) {
	.quiz .quiz_box {
		padding: 30px 10px 70px !important;
	}
	.quiz .quiz_box .heading h5 {
		font-size: 14px;
	}
	.quiz .quiz_box form .form-group .form-control::placeholder {
		font-size: 14px !important;
	}
	.quiz .quiz_box form {
		max-width: 100%;
	}
	.quiz .quiz_box .heading h2 {
		font-size: 20px;
	}
	.quiz .quiz_box .progress .progress_count {
		right: 0;
		left: 0;
		margin: 0px auto;
		text-align: center;
		margin-top: 14px;
	}
	.quiz .quiz_box .progress {
		width: 90%;
	}
	.quiz .quiz_box .quiz_ques h2 {
		font-size: 18px;
	}
	.quiz .quiz_box .answer {
		max-width: 100%;
	}
	.quiz .quiz_box .answer button.franchise-btn {
		padding: 16px 25px;
		font-size: 14px;
	}
	.quiz .quiz_box .quiz_ques {
		padding: 20px 0;
	}
	.MuiStepper-root.MuiStepper-horizontal.css-m5vj9m-MuiStepper-root {
		display: flex;
		flex-wrap: wrap;
		padding: 20px 10px;
	}
	.css-1bw0nnu-MuiStep-root {
		padding-left: 8px;
		padding-right: 8px;
		padding-bottom: 10px;
	}
	.css-19kzrtu {
		padding: 4px !important;
	}
}
@media (max-width: 281px) {
	.css-19kzrtu {
		padding: 0px !important;
	}
	.quiz .quiz_box .answer button.franchise-btn {
		padding: 16px 25px;
		font-size: 11px;
	}
	.quiz .quiz_box .quiz_ques h2 {
		font-size: 12px;
	}
	.MuiStepper-root.MuiStepper-horizontal.css-m5vj9m-MuiStepper-root {
		display: flex;
		flex-wrap: wrap;
	}
}
/* Quiz Css End Here */
label.done-label {
	margin-right: 21px;
	display: flex;
	justify-content: center;
}

.input-and-button input {
	width: 100%;
	padding: 7px;
	border-radius: unset;
}

.input-click-btn button {
	width: 100%;
	padding: 7px;
	margin-top: 11px;
	background-color: #249fdb;
	color: #ffffff;
	border: unset;
}
