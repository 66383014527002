.state-ul ul {
	padding: 0px 21px;
}

.state-ul ul li a {
	font-size: 13px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 600;
}

.find-frnchise h3 {
	font-size: 25px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 500;
}

.find-frnchise p {
	font-size: 13px;
}

.all-things-newd {
	background-color: #e3f4fb;
	padding: 30px;
	border: 1px solid #249fd8;
}

.all-things-newd .form-group select {
	background-color: #ffffff;
	border: 1px solid #d4d4d4;
	border-radius: unset;
}

.find-frnchise p {
	font-size: 12px;
}

.all-things-newd select#exampleFormControlSelect1 {
	font-size: 12px;
	color: #7070709e;
	font-family: "Poppins";
	height: 50px;
}

.by-state-head p {
	font-size: 15px;
	color: #249fdb;
	margin: 0;
	font-family: "Poppins";
	font-weight: 600;
}

.by-state-head h2 {
	font-size: 49px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
}

section.by-state-sec {
	/* display: flex; */
	padding: 70px;
}

section.by-state-sec .row {
	display: flex;
	/* align-items: center; */
}

::marker {
	color: #249fdb;
	border-radius: unset !important;
}

.state-ul ul li a {
	font-size: 14px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 400;
	white-space: nowrap;
	line-height: 50px;
}

section.by-state-sec .col-md-8 {
	padding-right: 100px;
}

.color-black ul li a {
	line-height: 3;
}
@media (max-width: 992px) {
	.by-state-head h2 {
		font-size: 29px;
	}
	section.by-state-sec {
		/* display: flex; */
		padding: 50px 0;
	}
}
@media (max-width: 768px) {
	.find-frnchise h3 {
		font-size: 15px;
	}
	.all-things-newd select#exampleFormControlSelect1 {
		height: 40px;
	}
}
@media (max-width: 576px) {
	section.by-state-sec {
		/* display: flex; */
		padding: 30px 0;
	}
	.by-state-head h2 {
		font-size: 27px;
	}
	section.by-state-sec .col-md-8 {
		padding-right: 50px;
	}
	.state-ul ul li a {
		font-size: 12px;
	}
}
@media (max-width: 281px) {
	section.by-state-sec {
		/* display: flex; */
		padding: 30px 0;
	}
	.state-ul ul li a {
		font-size: 11px;
	}
	section.by-state-sec .col-md-8 {
		padding-right: 37px;
	}
	.state-ul ul {
		padding: 0px 11px;
	}
}
