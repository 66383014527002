.news-letter-head h3 {
    font-size: 30px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 0;
    padding-bottom: 15px;
}

.news-letter-head p {
    font-size: 20px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 400;
    padding-bottom: 15px;
}


.news-letter-sec .form-news-letter button {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #249FDB;
    border: none;
    padding: 12px;
    font-weight: 500;
}


.news-letter-sec .form-group input {
    border: 1px solid #D4D4D4;
    border-radius: unset;
    margin: 10px 0px;
    padding: 28px;
    text-align: center;
}

.news-letter-sec .form-group input::placeholder {
    font-size: 16px;
    color: #00000052;
    font-family: 'Poppins';
    font-weight: 400;
}

.news-letter-form {
    width: 40%;
    margin: 0 auto;
}

.news-letter-head {
    text-align: center;
}

section.news-letter-sec {
    padding: 90px 0px;
}

.news-letter-head h5 {
    font-size: 25px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 700;
}

h3.enter-email {
    font-size: 50px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 600;
    padding-bottom: 30px;
}

.enter-email-para {
    text-align: center;
    padding-top: 100px;
}

.enter-email-para p {
    font-size: 15px;
    color: #636060;
    font-family: 'Poppins';
    font-weight: 300;
}

.enter-email-para a {
    font-size: 15px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 500;
}