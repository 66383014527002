.category-img img {
	background-color: #e3f4fb;
	padding: 15px 15px;
	width: 30%;
	margin: 20px 0px;
}
.category-head h5 {
	font-size: 21px;
	color: #249fdb;
	font-family: "Poppins";
	font-weight: 500;
	margin: 0;
}

.category-img {
	display: flex;
	align-items: center;
}

.category-text {
	padding-left: 20px;
}

.category-head h2 {
	font-size: 49px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
}

.pagination-all nav ul li a {
	color: #00151f;
	font-family: "Poppins";
}

.pagination-all {
	padding-top: 90px;
}

.category-text h5 {
	font-size: 14px;
	color: #00151f;
}

.allthings-table button {
	font-size: 12px;
	background-color: #249fdb;
	width: 70%;
	border: none;
	padding: 12px;
	color: #ffffff;
	font-family: "Poppins";
	letter-spacing: 1px;
	margin: 0 auto;
	cursor: pointer;
	outline: none;
}

.allthings-table {
	text-align: center;
}
@media (max-width: 992px) {
	.category-img img {
		background-color: #e3f4fb;
		padding: 6px 5px;
		width: 30%;
		margin: 20px 0px;
	}
	.category-text {
		padding-left: 7px;
	}
	.category-text h5 {
		font-size: 14px;
	}
}
@media (max-width: 768px) {
	.category-head h2 {
		font-size: 27px;
	}
	.allthings-table button {
		font-size: 12px;
		padding: 8px;
	}
}
@media (max-width: 576px) {
	.category-img img {
		padding: 4px 4px;
	}
	.category-text h5 {
		font-size: 12px;
	}
}

@media (max-width: 281px) {
	.category-text h5 {
		font-size: 11px;
		color: #00151f;
	}
	.category-text {
		padding-left: 6px;
	}
	.category-img img {
		padding: 6px 6px;
	}
}
