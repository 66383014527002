.about-head h5 {
	font-size: 20px;
	color: #249fdb;
	font-family: "Poppins";
	font-weight: 600;
	margin: 0;
	text-transform: capitalize;
}

.about-head h2 {
	font-size: 49px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
}

.about-head p {
	font-size: 14px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 400;
	margin: 22px 0px;
}

section.about-sec {
	padding: 70px;
}

.about-two-pics img {
	width: 10%;
}

.about-two-pics img {
	margin-right: 30px;
}
@media (max-width: 912px) {
}
@media (max-width: 575px) {
	section.about-sec {
		padding: 20px;
	}
	.about-head h2 {
		font-size: 27px;
		margin: 8px 0;
	}
	.about-head h5 {
		font-size: 16px;
	}
	section.banner {
		height: 300px;
	}
	.about-head p {
		font-size: 12px;
		margin: 7px 0 22px 0px;
	}
}
@media (max-width: 375px) {
	section.about-sec {
		padding: 20px;
	}
	.about-head h2 {
		font-size: 27px;
	}
	section.about-sec {
		padding: 10px 0;
	}
}
