.finance-buisness h5 {
	font-size: 21px;
	color: #249fdb;
	font-family: "Poppins";
	font-weight: 600;
	margin: 0;
}

.finance-buisness h2 {
	font-size: 49px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
	padding-bottom: 27px;
}
.finance-buisness p {
	font-size: 13px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 400;
}

.finance-buisness p span {
	font-weight: 600;
}

.ul-finance ul {
	list-style: auto;
	padding: 0px1 0px;
	padding: 0px 16px;
}

.ul-finance li {
	font-size: 12px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 400;
}

.ul-finance ul li span {
	font-weight: 600;
}

section.financing-sec {
	padding: 80px;
}

section.obligation-sec {
	background-color: #808080;
	text-align: center;
	padding: 50px;
}
.obligation-sec h4 {
	font-size: 21px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 600;
}

.obligation-sec h2 {
	font-size: 40px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 600;
}

.free-consultation a {
	font-size: 16px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 400;
	background-color: #249fdb;
	padding: 10px 40px;
}

.free-consultation {
	margin-top: 60px;
}

.finance-text h5 {
	font-size: 17px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 500;
}

.finance-text p {
	font-size: 14px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 400;
	margin: 0;
}

.pic-and-text-finance-text {
	display: flex;
	align-items: center;
	/* justify-content: space-evenly; */
}

section.add-fax-sec {
	padding: 0px;
	padding-bottom: 50px;
}

.finance-text {
	padding-left: 14px;
}

.free-consultation button {
	font-size: 12px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 400;
	background-color: #249fdb;
	padding: 10px 21px;
	line-height: 1;
	border: 1px solid #249fdb;
	width: 30%;
	border-radius: unset;
}

.fax-padding {
	padding-top: 40px !important;
}

@media (max-width: 576px) {
	section.financing-sec {
		padding: 40px 0;
	}
	.pic-and-text-finance-text {
		margin-bottom: 20px !important;
	}
	.finance-buisness h2 {
		font-size: 27px;
		padding-bottom: 0px;
	}
	.finance-buisness p {
		font-size: 12px;
	}
	section.obligation-sec {
		padding: 50px 0;
	}
	.obligation-sec h2 {
		font-size: 28px;
	}
	.free-consultation button {
		width: 40%;
	}
}
@media (max-width: 281px) {
	.finance-buisness h2 {
		font-size: 30px;
		padding-bottom: 7px;
		padding-top: 10px;
	}
	.free-consultation button {
		width: 60%;
	}
	.pic-and-text-finance-text {
		margin-bottom: 20px !important;
	}
	.all-forms {
		width: 90% !important;
		margin: 0 auto;
	}
	.finance-text p {
		font-size: 11px;
	}
}
