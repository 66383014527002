.center-img-btn a {
  background-color: #249fdb;
  cursor: pointer;
}

.center-img-btn a {
  font-size: 16px;
  color: #ffffff;
  font-family: "Poppins";
  width: 100%;
  display: block;
  text-align: center;
  padding: 16px 0px;
  margin-top: 10px;
  text-decoration: unset;
}

.tab-sec-all .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  font-size: 12px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 300;
  background-color: #000000;
}
.home-things-para p {
  font-size: 0.87rem;
  color: #00151f;
  font-family: "Poppins";
  font-weight: 400;
}

.painting-ul ul li {
  font-size: 12px;
  color: #00151f;
  font-family: "Poppins";
  font-weight: 400;
  padding: 6px;
}

.painting h3 {
  font-size: 16px;
  color: #00151f;
  font-family: "Poppins";
  font-weight: 600;
}

.painting-ul ul {
  padding: 0px 20px;
}

.painting {
  padding-top: 50px;
  padding-bottom: 20px;
}
.establish h5 {
  font-size: 15px;
  color: #249fdb;
  font-family: "Poppins";
  font-weight: 500;
  margin: 0;
}

.establish p {
  font-size: 0.87rem;
  color: #00151f;
  font-family: "Poppins";
  font-weight: 500;
  margin: 0;
}

.establish {
  border-bottom: 1px solid #249fd8;
  padding-bottom: 11px;
  padding-top: 21px;
}
.establish ul {
  padding: 0;
  list-style-type: none;
}

.establish ul > li {
  list-style: none;
  font-size: 0.87rem;
  color: #00151f;
  font-family: "Poppins";
  font-weight: 500;
  margin: 0;
}

.year-established {
  background-color: #e3f4fb;
  border: 4px solid #e3f4fb;
  padding: 10px;
  margin-top: 10px;
}

section.tab-sec {
  /* padding: 77px 0px; */
  padding: 15px 0px;
}
section.tab-sec .man-img {
  height: 200px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}

section.tab-sec .man-img img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.tab-sec-all .nav-pills .nav-link {
  font-size: 12px;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 400;
  border-radius: 0.25rem;
  background-color: #249fdb;
  border-radius: unset;
  margin-right: 40px;
  width: 100%;
  text-align: center;
}

.painting-ul ul {
  list-style: none;
}

.painting-ul ul li::before {
  content: url(../../Assets/img/checkback.png);
  position: absolute;
  left: 13px;
}

.text-video h3 {
  font-size: 20px;
  color: #00151f;
  font-family: "Poppins";
  font-weight: 600;
}

.text-video p {
  font-size: 12px;
  color: #828282;
  font-family: "Poppins";
  font-weight: 300;
  padding-top: 10px;
}

.text-video span {
  font-size: 10px;
  color: #000000;
  font-family: "Poppins";
}

.video-img img {
  position: relative;
  margin: 10px 0px;
}

.play1 {
  position: absolute;
  top: 80px;
  bottom: unset;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play2 {
  position: absolute;
  top: unset;
  bottom: 90px;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play1 i {
  color: #249fdb;
}

.play2 i {
  color: #249fdb;
}

section.center-pro-sec {
  padding-top: 90px;
}

.play1 i {
  background-color: #ffffff;
  padding: 22px;
  border-radius: 50px;
  width: 60px;
  text-align: center;
}

.play2 i {
  background-color: #ffffff;
  padding: 22px;
  border-radius: 50px;
  width: 60px;
  text-align: center;
}

.doorrenew img {
  width: 400px;
  height: 200px;
}

.center-pro-sec .cenetr-img {
  width: 100%;
  height: 320px;
  overflow: hidden;
  object-fit: cover;
}

.center-pro-sec .cenetr-img img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.center-pro-sec .center-pro-img {
  width: 255px;
  height: 255px;
  overflow: hidden;
  /* object-fit: contain; */
  margin: 0 auto;
}

.center-pro-sec .center-pro-img img {
  width: 255px;
  height: 255px;
  /* object-fit: contain; */
}
