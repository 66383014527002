section.franchise101_page .head-sub-head {
	padding: 1rem 0 1rem 0;
}
section.franchise101_page ul li {
	padding: 16px 5px;
	font-weight: bold;
}

section.franchise101_page ul li .download-icon {
	font-size: 22px;
	margin-left: 16px;
}
section.franchise101_page div#accordion button.btn.btn-link {
	color: #000;
	font-family: "Poppins";
	font-weight: bold;
	position: relative;
	width: 100%;
	text-align: left;
}
section.franchise101_page div#accordion .card-body {
	color: #00151f;
	font-size: 14px;
}
section.franchise101_page div#accordion .card-header {
	background: transparent;
	/* border-bottom: 0; */
	position: relative;
}

section.franchise101_page div#accordion button.btn.btn-link::after {
	/* content: "-"; */
	font-family: "Poppins";
	position: absolute;
	right: 10px;
	font-weight: 100;
	top: 5px;
	/* border: 1px solid #000; */
	/* border-radius: 50%; */
	width: 30px;
	display: flex;
	justify-content: center;
	font-size: 20px;
	font-weight: 500;
}
section.franchise101_page div#accordion button.btn.btn-link.collapsed::after {
	/* content: "+"; */
	font-family: "Poppins";
	position: absolute;
	right: 10px;
	font-weight: 100;
	top: 5px;
	/* border: 1px solid #000; */
	/* border-radius: 50%; */
	width: 30px;
	display: flex;
	font-size: 20px;
	font-weight: 500;
	justify-content: center;
}
section.franchise101_page {
	padding-bottom: 3rem;
}
@media (max-width: 576px) {
	section.franchise101_page div#accordion button.btn.btn-link {
		font-size: 17px;
		white-space: normal;
	}
	section.franchise101_page iframe {
		width: 100%;
	}
	section.franchise101_page div#accordion button.btn.btn-link.collapsed::after {
		right: -7px;
	}
}
@media (max-width: 281px) {
	section.franchise101_page ul {
		padding: 0px 11px;
	}
	section.franchise101_page ul li a {
		font-size: 10px;
	}
	.head-sub-head h2 {
		font-size: 30px;
	}
	section.franchise101_page div#accordion button.btn.btn-link {
		font-size: 14px;
		white-space: normal;
	}
	section.franchise101_page div#accordion button.btn.btn-link.collapsed::after {
		right: -20px;
	}
}
