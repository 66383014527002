/* Main Header Css End here */
/* mobile header start here */

header .mobile-header {
	width: 40%;
	height: 100%;
	position: fixed;
	background: #000;
	top: 0;
	z-index: 1;
	right: 0;
	padding-top: 30px;
	transition: 0.7s;
	transform: translateX(100%);
	overflow-y: scroll;
	z-index: 99999;
	padding: 12px;
}
header .mobile-header.show {
	transform: translateX(0%);
}
header .mobile-header ul.mobile-nav {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
}
header .mobile-header .cancel {
	background: #fff;
	width: 35px;
	/* text-align: center; */
	height: 35px;
	line-height: 55px;
	margin: 0px 20px 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
header .mobile-header ul.mobile-nav li.nav-item {
	padding: 10px 0;
	border: 0;
	border-bottom: 1px solid #dddddd4a;
}
header .mobile-header ul.mobile-nav li.nav-item a {
	color: #fff;
	font-size: 14px;
	padding: 0 20px;
	text-transform: uppercase;
}
header .mobile-header .secondary-items {
	list-style-type: none;
	padding: 0px !important;
}
header .mobile-header .secondary-items li {
	padding: 5px 22px !important;
	line-height: 33px;
	border-bottom: 1px solid;
}
.mobile-header ul li a {
	font-family: "Montserrat-Regular";
	font-size: 17px !important;
	color: #fff !important;
	text-transform: uppercase;
}
.canvas-icon.text-black {
	display: none;
}
.navbarMenu .canvas-icon i.fa.fa-bars {
	font-size: 27px;
}

.canvas-icon.text-black {
	display: none;
}
.navbarMenu .canvas-icon i.fa.fa-bars {
	font-size: 27px;
}

.search_box {
	position: absolute;
	width: 100%;
	background: #fff;
	padding: 30px 0 30px;
	top: 0;
	z-index: 9;
	transform: translateY(-180%);
	transition: 0.6s;
}
.search_box.active {
	transform: translateY(0%);
}
.search_box .form-group {
	display: flex;
}
.search_box .form-group input.form-control {
	border-radius: 0;
	font-family: "Poppins";
	border-bottom: 1px solid #ddd;
}
.search_box .form-group button {
	border-radius: 0;
}
header .secondary-header-wrapper {
	padding: 5px 150px;
}
.search_box .close_btn {
	position: absolute;
	right: 20px;
	top: 20px;
}
.search_box .close_btn button {
	color: #00b786e9;
	opacity: 1;
	font-size: 25px;
	font-weight: 400;
	width: 40px;
	height: 40px;
}

/* mobile header end here */
/* header responsive queries start here*/
/* @media (max-width: 1440px) {
    header .custom-width {
      padding: 0px 5%;

    }
  }

  @media (max-width: 1366px) {
    header .custom-width {
      padding: 0px 3%;

    }
  } */

@media (max-width: 1280px) {
	header .navbarMenu ul.navItems li.menu-items a {
		font-size: 10px;
	}
	header .navbarMenu ul.navItems li.menu-items {
		padding-right: 9px;
	}
}

@media (max-width: 1150px) {
	header .navbarMenu {
		display: flex;
		justify-content: flex-end;
	}
}

@media (max-width: 1198px) {
	/* .canvas-icon.text-black {
		display: block;
	} */
	/* .secondaryHeader {
		display: none;
	} */
	header .main-header .logoDv {
		margin: 0px !important;
	}
}
@media (max-width: 1198px) {
	header ul.navItems {
		display: none;
	}
	header li.dropdown:hover .dropdown-menu {
		display: block;
		background: #000;
	}
	.main-header a#navbarDropdown {
		padding: 0px 20px;
	}
	/* .margin_footer {
		margin-top: 20px;
	} */
}
@media (max-width: 1025px) {
	#headerTop .navbar_menus ul li {
		margin: 0 -1px;
	}
	#headerTop .navbar_menus ul li .dropdown button.btn {
		font-size: 10px;
	}
	#headerTop .navbar_menus ul li a {
		font-size: 10px;
	}
	section.find-franchise-sec .searchBtn {
		padding: 10px 39px;
	}
}

@media (max-width: 991px) {
	.canvas-icon.text-black {
		display: block;
	}
	.canvas-icon.text-black.heade-smoll {
		display: flex;
		align-content: center;
		justify-content: end;
	}
	.head-sub-head h3 {
		font-size: 30px;
	}
	.cash-Head-para p {
		font-size: 12px;
	}
	.login a {
		font-size: 14px;
		padding-right: 0;
	}
	section.find-franchise-sec .searchBtn {
		padding: 9px 39px;
	}
	/* .secondaryHeader {
		display: block;
	} */
}
@media (max-width: 767px) {
	.canvas-icon.text-black {
		display: block;
	}
	.heade-smoll {
		display: flex !important;
		gap: 10px;
		align-items: center;
		justify-content: end;
	}
	.heade-smoll .set_icon {
		color: #00bc26;
		font-size: 25px !important;
	}
	.search_box .close_btn button {
		opacity: 0;
	}
	.search_box {
		background: #fff;
		padding: 10px 0px;
		color: #ddd;
	}
}

@media (max-width: 764px) {
	span.chat-count {
		margin-left: 7px;
	}
}

@media (max-width: 576px) {
	header .mobile-header {
		width: 100%;
		height: 93vh;
	}
}
@media (max-width: 320px) {
	header .mobile-header {
		width: 80%;
	}
	.dropdown-menu {
		background-color: #000;
	}
}
@media (max-width: 281px) {
	header .mobile-header {
		width: 100%;
	}
	.dropdown-menu {
		background-color: #000;
	}
	.mobile-header ul li a {
		font-size: 14px !important;
	}
	header .mobile-header {
		width: 100%;
		height: 86vh;
	}
}

/* header responsive queries start here*/
/* Header css End here */
